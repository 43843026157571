import Cookies from 'js-cookie';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useConfig } from '@Src/providers/ConfigProvider';
import { ReactComponent as LeftArrowIcon } from '@Src/assets/images/arrow-left-long.svg';
import { CUSTOM_EVENTS, publishCustomEvent } from '@Src/utility/CustomEvents';
import Dialog from '@Components/ui/Dialog';
import { TextField } from '@Components/ui/TextField';
import { IconButton, Typography, useMediaQuery } from '@mui/material';
import { PhoneCallButton } from '../PhoneCallButton';
import useApi from '@Src/hooks/useApi';
import { useFeatureFlag } from '@Src/providers/FeatureFlagsProvider';

const StyledDialog = styled(Dialog)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    & .MuiPaper-root {
      width: 480px;
    }
  }
`;
const StyledContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(2)};
`;

const StyledCloseIcon = styled(IconButton)`
  padding: 0;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledHeaderContainer = styled('div')`
  margin: ${({ theme }) => `${theme.spacing(3)} 0`};
`;

const StyledTextField = styled(TextField)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const StyledErrorMessage = styled(Typography)`
  margin: 5px 0;
  color: ${({ theme }) => theme.palette.error.main};
`;

type SmsCodeDialogProps = {
  onBackClick: () => void;
  onClose: () => void;
  phone: string;
};
const SmsCodeDialog = ({
  onBackClick,
  onClose,
  phone,
}: SmsCodeDialogProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [sms, setSMS] = useState<string>('');
  const smsInputRef = useRef<HTMLInputElement>();
  const { translate } = useTranslation();
  const { appId, isCustomDomain, vanityUrlSuffix } = useConfig();
  const theme = useTheme();
  const { get, post } = useApi();
  const enableSafariLogin = useFeatureFlag('enableSafariLogin');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setTimeout(() => {
      smsInputRef?.current?.focus();
    }, 10);
  });
  const setAuthCookie = (flipdishToken: string): any => {
    if (!flipdishToken) return;

    const sixMonthsDate = new Date();
    sixMonthsDate.setMonth(sixMonthsDate.getMonth() + 6); // Expires in 6 months
    let cookiePath = '/';
    // Need to set cookie path with appId in case of panacea URLs
    if (!isCustomDomain) {
      if (vanityUrlSuffix) {
        cookiePath = `/${vanityUrlSuffix}`;
      } else {
        cookiePath = `/${appId}`;
      }
    }
    Cookies.set('flipdish-token', flipdishToken, {
      expires: sixMonthsDate,
      path: cookiePath,
      // To test locally, we need to set this to false
      secure: true,
      // We need to do this for datadog browser tests as they are in iframe.
      sameSite: appId === 'fd13544' ? 'none' : 'strict',
    });

    setUserData();
  };

  const setUserData = (): void => {
    publishCustomEvent({ name: CUSTOM_EVENTS.USER_LOGIN });

    void get<{ Data: { UserId: number } }>({
      path: `/Account/AccountDetails`,
    }).then((response) => {
      if (!response.error) {
        const userData = response.data?.Data;
        try {
          // TODO: set this data is user store also and use that data in photon
          localStorage.setItem('flipdish-customer', JSON.stringify(userData));
        } catch (e) {
          console.error(e);
        }
      }
    });
  };

  const submitSmsCode = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const code = (e.target.value || '').trim();
    if (code.length >= 4) {
      const payload = {
        phoneNumber: phone,
        smsCode: code,
      };

      try {
        const genericHeaders: Record<string, string> = {
          'Content-Type': 'application/json',
          'Flipdish-App-Type': 'Photon',
          'Flipdish-Site-Type': 'photon-v2',
          'Flipdish-White-Label-Id': appId,
        };

        const { data, headers } = await post<typeof payload, string>({
          path: '/Account/LoginUsingPhoneNumber',
          payload,
          suppresCookieHeader: true,
          customHeaders: genericHeaders,
        });
        if (data?.Success) {
          if (!enableSafariLogin) {
            const authToken = headers?.get('x-flipdish-token') || '';
            if (authToken !== '') {
              setAuthCookie(authToken);
              setTimeout(() => {
                window.location.reload();
              }, 100);
            } else {
              setErrorMessage('Unable to login');
            }
          } else {
            setUserData();
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
        } else if (data?.UserMessage) {
          setErrorMessage(data?.UserMessage);
          setSMS('');
        } else {
          setErrorMessage('Something went wrong');
        }
      } catch (e) {
        setErrorMessage('Unable to login');
      }
    }
    setSMS(code);
  };

  return (
    <StyledDialog
      open
      onCloseDialog={onClose}
      fullScreen={isSmallScreen}
      fullWidth={isSmallScreen}
    >
      <StyledContainer>
        <StyledCloseIcon
          aria-label="back to phone number entry"
          className="backIcon"
          data-fd="back-dialog-icon"
          disableRipple
          onClick={onBackClick}
        >
          <LeftArrowIcon />
        </StyledCloseIcon>
        <StyledHeaderContainer>
          <Typography variant="h2">{translate('SMS_Title')}</Typography>
          <Typography variant="body2">
            {`${translate('SMS_SubTitle')} ${phone}`}
          </Typography>
        </StyledHeaderContainer>
        <StyledTextField
          aria-label="Please enter SMS code"
          autoFocus
          helperText={errorMessage}
          error={errorMessage !== ''}
          id="smsCode"
          name="smsCode"
          inputRef={smsInputRef}
          placeholder={translate('SMS')}
          type="number"
          value={sms}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onChange={submitSmsCode}
        />
        <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        <PhoneCallButton
          phoneNumber={phone}
          onError={() =>
            setErrorMessage(
              translate('There_was_an_error_requesting_a_phone_call'),
            )
          }
        />
      </StyledContainer>
    </StyledDialog>
  );
};

export default SmsCodeDialog;
